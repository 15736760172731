
import request from '../request'

export function fetchListNum () {
    return request({
        url: '/bim/calculateBook/query/typeNum',
    })
}

export function fetchList (params) {
    return request({
        url: '/bim/calculateBook/list',
        params
    })
}

export function fetchDetail (params) {
    return request({
        url: '/bim/calculateBook/query/' + params.id,
    })
}

export function fetchDetailByProjId (params) {
    return request({
        url: '/bim/calculateBook/query',
        params
    })
}

export function addBookmark(data) {
    return request({
        url: '/bim/item/bookmark/add',
        method: 'POST',
        data
    })
}

export function deleteBookmark(data) {
    return request({
        url: '/bim/item/bookmark/delete',
        method: 'POST',
        data
    })
}

export function importFile(data) {
    return request({
        url: '/bim/calculateBook/import',
        method: 'post',
        data,
    })
}