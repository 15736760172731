import { render, staticRenderFns } from "./param.vue?vue&type=template&id=159f3d57&scoped=true"
import script from "./param.vue?vue&type=script&lang=js"
export * from "./param.vue?vue&type=script&lang=js"
import style0 from "./param.vue?vue&type=style&index=0&id=159f3d57&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159f3d57",
  null
  
)

export default component.exports