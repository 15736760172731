<template>
  <div class="panel">
    <div class="list">
      <div class="title">
        <span class="icon"></span>
        <span>构建清单</span>
      </div>

      <div>
        <div
          class="item"
          v-for="(item, index) in menu"
          :key="item.id"
          @click="selectedId = item.id"
          :class="selectedId === item.id ? 'active' : ''"
        >
          <div>{{ item.name }}</div>
          <img src="@/assets/icons/delete.png" @click="remove(index)" alt />
        </div>
      </div>

      <button class="primary-button" @click="save">
        {{ saveLoading ? "加载中..." : "开始设计" }}
      </button>
    </div>

    <div class="card">
      <div class="title">
        <span class="icon"></span>
        <span>设计参数</span>

        <button class="primary-button" @click="cacluate">
          {{ getValueLoading ? "加载中..." : "公式计算" }}
        </button>
      </div>
      <div class="query">
        <a-input style="width: 200px;" placeholder="名称" v-model="name" @pressEnter="query" @change="resetStyle" :allowClear="true"/>
        <a-radio-group v-model="dataLevel" @change="queryByDataLevel">
          <a-radio-button value="all">
            全部
          </a-radio-button>
          <a-radio-button value="0">
            0级
          </a-radio-button>
          <a-radio-button value="1">
            1级
          </a-radio-button>
          <a-radio-button value="2">
            2级
          </a-radio-button>
          <a-radio-button value="3">
            3级
          </a-radio-button>
          <a-radio-button value="4">
            4级
          </a-radio-button>
          <a-radio-button value="bookmark">
            收藏
          </a-radio-button>
        </a-radio-group>
        <div class="button-group">
          <button class="reset-button" v-if="!isShowAll" @click="showAll">全部展开</button>
          <button class="reset-button" v-if="isShowAll" @click="showMain">收起</button>
        </div>
      </div>
      <a-skeleton v-if="loading" />
      <div v-else>
        <table class="table">
          <tr class="header">
            <td style="flex: 1">中文名</td>
            <td style="width: 120px">设计值</td>
            <td style="width: 120px; text-align: center">单位</td>
            <td style="width: 120px; text-align: center">类型</td>
            <td style="width: 240px">参考信息</td>
          </tr>
          <tr class="row" v-for="item in list" :key="item.id">
            <td style="flex: 1">
              <span class="name">
                <a-icon type="star" theme="filled" style="color: #fadb14;" v-if="item.bookmark=='1'" @click="changeBookMark(item)"/>
                <a-icon type="star" style="color: #e8e8e8;" v-else @click="changeBookMark(item)"/>
                <span style="margin-left: 10px;">{{ item.name }}</span>
              </span>
          </td>
            <td style="width: 120px">
              <span v-if="String(item.valueType) === '2'">
                <a-input
                  size="small"
                  style="width: 100px"
                  :value="item.designValue"
                  :disabled="item.disabled"
                  @change="
                    (e) => {
                      onChangeParam(e.target.value, item.id);
                    }
                  "
                />
              </span>

              <span v-else-if="String(item.valueClass) === '1'">
                <a-input-number
                  size="small"
                  style="width: 100px"
                  :value="item.designValue"
                  :disabled="item.disabled"
                  :precision="
                    typeof item.decimalPrecision === 'number'
                      ? item.decimalPrecision
                      : 0
                  "
                  @change="
                    (e) => {
                      onChangeParam(e, item.id);
                    }
                  "
                />
              </span>
              <span v-else>
                {{ item.designValue }}
              </span>
            </td>
            <td style="width: 120px; text-align: center">{{ item.unit }}</td>
            <td style="width: 120px; text-align: center">
              <span v-if="item.valueClass === '1'">数值</span>
              <span v-if="item.valueClass === '2'">公式</span>
              <span v-if="item.valueClass === '3'">公式</span>
            </td>
            <td style="width: 240px">{{ item.remark }}</td>
          </tr>
        </table>
      </div>
    </div>

    <a-modal :visible="visible" title="选择任务类型" @ok="ok" @cancel="cancel">
      <a-select
        style="width: 320px"
        v-model="stateCode"
        placeholder="任务类型"
        mode="tags"
      >
        <a-select-option
          v-for="item in statusList"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import { fetchDetailByProjId as fetchDetail, addBookmark, deleteBookmark } from "@/api/book";
import { add, fetchValues } from "@/api/task";
export default {
  data() {
    return {
      menu: [],
      selectedId: "",
      loading: false,
      saveLoading: false,

      visible: false,
      stateCode: [],

      getValueLoading: false,
      statusList: [
        {
          name: "抗浮分析",
          value: "0x2000",
        },
        {
          name: "结构分析",
          value: "0x1000",
        },
        {
          name: "工艺构件",
          value: "0x8",
        },
        {
          name: "结构构件",
          value: "0x10",
        },
        {
          name: "尺寸标注",
          value: "0x40",
        },
      ],
      isShowAll: false,
      isCacluated: false,
      allList: [],
      name: "",
      dataLevel: "all"
    };
  },

  mounted() {
    const str = window.localStorage.getItem("selected-books");
    if (str) {
      const list = JSON.parse(str);
      console.log("list", list);
      this.menu = list;
      this.selectedId = list[0].id;
    }
  },

  watch: {
    selectedId() {
      this.isShowAll = false;
      this.isCacluated = false;
      this.name = "";
      this.getParams();
    },
  },
  computed: {
    list() {
      if (this.menu.length > 0 && this.selectedId) {
        const list = this.menu.find((item) => item.id === this.selectedId).list;
        return list ? list : [];
      } else {
        return [];
      }
    },
  },

  methods: {
    remove(index) {
      if (this.menu.length === 1) {
        this.$message.error("请至少保留一项");
        return;
      }
      const arr = [...this.menu];
      arr.splice(index, 1);
      this.menu = arr;
      this.selectedId = arr[0].id;
      window.localStorage.setItem("selected-books", JSON.stringify(this.menu));
    },

    onChangeParam(e, itemId) {
      console.log("e", e, itemId);
      // const idx = this.bookList.findIndex((item) => item.id === bookId);
      const book = this.menu.find((item) => item.id === this.selectedId);
      const selectedIndex = this.menu.findIndex(
        (item) => item.id === this.selectedId
      );
      const list = book.list;
      const index = list.findIndex((item) => item.id === itemId);
      const allListIndex = this.allList.findIndex((item) => item.id === itemId);
      this.allList.splice(allListIndex, 1, {
        ...this.allList[allListIndex],
        designValue: e,
        manualModify: "1",
      });
      list.splice(index, 1, {
        ...list[index],
        designValue: e,
        manualModify: "1",
      });

      this.menu.splice(selectedIndex, 1, {
        ...book,
        list,
      });
    },
    getParams() {
      if (this.menu.length > 0 && this.selectedId) {
        const item = this.menu.find((item) => item.id === this.selectedId);
        const index = this.menu.findIndex(
          (item) => item.id === this.selectedId
        );

        this.loading = true;
        fetchDetail({
          id: this.selectedId,
          projId: window.localStorage.getItem("selectedProjectId"),
        })
          .then((res) => {
            console.log("book detail", res);

            const list = Array.isArray(res.bimItemList) ? res.bimItemList : [];
            this.allList = list;
            let showList;
            if(! this.isShowAll) {
              showList = list.filter(v=>v.displayLevel === 'A');
            }else{
              showList = list;
            }
            if (this.dataLevel !== 'all') {
              if(this.dataLevel == 'bookmark') {
                showList = showList.filter(v=>v.bookmark === '1');
              }else {
                showList = showList.filter(v=>v.dataLevel === this.dataLevel);
              }
            }
            this.menu.splice(index, 1, {
              ...item,
              list: showList,
              defaultList: list
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getValue() {
      this.getValueLoading = true;

      const index = this.menu.findIndex((item) => item.id === this.selectedId);
      const active = this.menu[index];
      fetchValues({
        pid: active.id,
        list: this.allList,
      })
        .then((res) => {
          console.log(res);
          if (res && Array.isArray(res.list)) {
            this.$message.success("查询成功");
            if(! this.isShowAll) {
              active.list = res.list.filter(v=>v.displayLevel === 'A');
            }else{
              active.list = res.list;
            }
            if (this.dataLevel !== 'all') {
              if(this.dataLevel == 'bookmark') {
                active.list = active.list.filter(v=>v.bookmark === '1');
              }else {
                active.list = active.list.filter(v=>v.dataLevel === this.dataLevel);
              }
            }
            this.menu.splice(index, 1, active);
          }
        })
        .finally(() => {
          this.getValueLoading = false;
        });
    },
    cacluate() {
      this.isCacluated = true;
      this.isShowAll = false;
      this.name = "";
      this.getValue();
    },
    save() {
      let flag = "";
      for (let i = 0; i < this.menu.length; i++) {
        if (!this.menu[i].list) {
          flag = this.menu[i].name;
          break;
        }
      }

      if (flag) {
        alert(`请检查${flag}设计参数`);
        return;
      }

      this.visible = true;
    },

    cancel() {
      this.visible = false;
    },

    ok() {
      if (this.stateCode.length === 0) {
        alert("请选择审批类型");
        return;
      }

      this.saveLoading = true;

      Promise.all([
        ...this.menu.map((item) => {
          return add({
            name: item.name,
            bookId: item.id,
            bookTypeSub: item.typeSub,
            body: JSON.stringify([...new Set([...item.list, ...item.defaultList])]),
            stateCode: this.stateCode.join(","),
            projectId: window.localStorage.getItem("selectedProjectId"),
          });
        }),
      ])
        .then((res) => {
          console.log("res", res);
          let errorMessage = "";
          res.forEach((item) => {
            if (item.code === 400) {
              errorMessage = item.message;
            }
          });
          if (errorMessage) {
            this.$error({
              title: "提示",
              content: errorMessage,
            });

            return;
          }

          this.stateCode = this.statusList.map((item) => item.value);
          this.visible = false;
          const that = this;
          this.$success({
            title: "提示",
            content: "创建任务成功，请耐心等待系统通知！",
            onOk() {
              that.$router.push("/task");
            },
          });
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    showMain() {
      this.isShowAll = false;
      if(this.isCacluated) {
        this.getValue();
      }else{
        this.getParams();
      }
    },
    showAll() {
      this.isShowAll = true;
      if(this.isCacluated) {
        this.getValue();
      }else{
        this.getParams();
      }  
    },
    resetStyle() {
      this.$nextTick(() => {
        if(this.name) return; 
        let list = this.$el.querySelectorAll(`.name`);
        list.forEach(v=>{
          v.style.backgroundColor = "";
        })
      })
    },
    query() {
      this.getValueLoading = true;

      const index = this.menu.findIndex((item) => item.id === this.selectedId);
      const active = this.menu[index];
      fetchValues({
        pid: active.id,
        list: this.allList,
      })
        .then((res) => {
          console.log(res);
          if (res && Array.isArray(res.list)) {
            this.$message.success("查询成功");
            if(! this.isShowAll) {
              active.list = res.list.filter(v=>v.displayLevel === 'A');
              active.list = active.list.filter(v=>v.name.indexOf(this.name) > -1);
            }else{
              active.list = res.list;
              active.list = active.list.filter(v=>v.name.indexOf(this.name) > -1);
            }
            this.menu.splice(index, 1, active);
          }
        })
        .finally(() => {
          this.getValueLoading = false;
        });
    },
    queryByDataLevel() {
      if(this.isCacluated) {
        this.getValue();
      }else{
        this.getParams();
      }
    },
    changeBookMark(item) {
      console.log(item)
      const index = this.menu.findIndex((item) => item.id === this.selectedId);
      const active = this.menu[index];
      const activeIndex = active.list.findIndex((v) => v.id === item.id);
      if(item.bookmark == '0') {
        addBookmark({
          cbId: this.selectedId,
          itemId: item.id
        }).then(() => {
          active.list[activeIndex].bookmark = '1';
          this.menu.splice(index, 1, active);
        })
      }
      if(item.bookmark == '1') {
        deleteBookmark({
          cbId: this.selectedId,
          itemId: item.id
        }).then(() => {
          active.list[activeIndex].bookmark = '0';
          this.menu.splice(index, 1, active);
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.panel {
  padding-left: 20px;
  display: flex;
  gap: 20px;

  .title {
    display: flex;
    align-items: center;
    color: #0562fd;
    font-size: 18px;
    font-weight: 600;
    line-height: 1em;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(207, 220, 238, 0.71);

    .icon {
      margin-top: 1px;
      height: 0.8em;
      width: 4px;
      margin-right: 8px;
      background-color: #0562fd;
    }

    .primary-button {
      margin-left: auto;
      display: block;
      color: #fff;
      font-size: 13px;
      line-height: 1em;
      padding: 10px 16px;
      background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
      box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
      border-radius: 20px;
    }
  }

  .list {
    background: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 20px;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      min-width: 240px;
      color: rgba(96, 112, 137, 0.69);
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);

      img {
        height: 16px;
        display: block;
      }

      &:last-child {
        border-bottom-width: 0;
      }
    }

    .active {
      color: #0560fd;
    }

    .primary-button {
      margin-top: 16px;
      width: 100%;
      display: block;
      color: #fff;
      height: 38px;
      line-height: 100%;
      background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
      box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
      border-radius: 20px;
    }
  }

  .card {
    flex: 1;
    background: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 20px;

    .table {
      height: 67vh;
      overflow: auto;
      width: 100%;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      .header {
        opacity: 0.7;
        display: flex;
        align-items: center;

        td {
          flex-shrink: 0;
          padding: 16px;
          background: #ebf2fc;
          font-weight: 400;

          color: #9ca4b1;
          font-size: 16px;
        }
        td:first-child {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
        td:last-child {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }

      .row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(207, 220, 238, 0.71);

        td {
          padding: 16px;
        }
      }
    }
  }
}

.control {
  color: #0667fd;
}
.query {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    input{
      width: 177px;
      height: 40px;
      padding: 0 12px;
      background: #f8f9fb !important;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
    }
    input::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #abb3c0;
    }
    .button-group {
      .primary-button {
        width: 71px;
        height: 38px;
        line-height: 100%;
        color: #fff;
        background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
        box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
        border-radius: 6px 0px 0px 6px;
      }

      .reset-button {
        margin-left: 20px;
        width: 75px;
        height: 38px;
        background: #fff;
        border-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border: 3px solid #eff1f6;
        box-sizing: border-box;
      }
    }
  }
</style>